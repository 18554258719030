import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components'
import {Button} from '@material-ui/core'

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  overflow: hidden;
  background-color: ${p => p.bg || 'rgba(0,0,0,.8)'};
`

const BgWrapper = styled.div`
  & img {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    border-radius: 20px;
    box-shadow: 0px 76px 52px -16px rgba(0, 0, 0, 0.45);
  }
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  position: relative;
`

const GridWrapper = styled.div`
  text-align: center;
  position: absolute;
  bottom: 25px;
  left: 25px;
  background-color: rgba(0,0,0,.2);
  border: 2px solid gray;
  border-radius: 5px;
  padding: 1em;
`

const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

`

const Color = styled.span`
  background-color: ${p => p.value};
  color: white;
  padding: .5em;
  &:before {
    text-transform: uppercase;
    content: "${p => p.value}";
  }
`

const CopyWrapper = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 1em;
  right: 0;
  & .MuiButton-label {
    text-transform: lowercase;
  }
`

const CopyButton = ({id}) => {
  const [command, setCommand] = useState('')
  useEffect(() => {
    fetch(`command/${id}`).then((res) => res.text()).then(command => setCommand(command))
  }, [id])

  const onClick = () => {
    const tmp = document.createElement('input')
    tmp.value = command
    document.body.appendChild(tmp)
    tmp.select()
    tmp.setSelectionRange(0, 99999)
    document.execCommand('copy')
    document.body.removeChild(tmp)
    alert('command copied to your clipboard')
  }

  return (
    <CopyWrapper>
      <Button onClick={onClick} variant="contained">copy cmd (curl)</Button>
    </CopyWrapper>
  )
}

const DEFAULT_BG_SIZE = 750

const Viewer = ({id, scheme}) => {
  const [zoom, setZoom] = useState(1)
  const {special, colors} = scheme || {}
  const {background} = special || {}
  const [{naturalWidth, naturalHeight}, setImageTarget] = useState({})

  const aspectDecimal = naturalHeight / naturalWidth

  const width = DEFAULT_BG_SIZE * zoom;

  const {origWidth, origHeight} = useMemo(() => {
    return {
      origWidth: width,
      origHeight: width * aspectDecimal
    }
  }, [naturalWidth, naturalHeight])

  useEffect(() => {
    const onWheel = e => {
      if (e.deltaY < 0) {
        setZoom(zoom + .05)
      }
      if (e.deltaY > 0) {
        setZoom(zoom - .05)
      }
    }
    window.addEventListener('wheel', onWheel)
    return () => window.removeEventListener('wheel', onWheel)
  }, [zoom])

  return (
    <Wrapper bg={background}>
      {id && 
        <BgWrapper height={origHeight} width={origWidth}> 
          <img src={`image/${id}`} style={{width}} onLoad={(e) => setImageTarget(e.target)}/>
          <CopyButton id={id}/>
        </BgWrapper>
      }
      <GridWrapper>
        {id ? 'colors' : 'loading...'}
        <ColorGrid>
          {Object.keys(colors || {}).map((key) => {
            return (
              <Color value={colors[key]}/>
            )
          })}
        </ColorGrid>
      </GridWrapper>
    </Wrapper>
  )
}

function App() {

  const [data, setData] = useState({})

  useEffect(() => {
    fetch('/rando').then((res) => res.json()).then(data => setData(data))
  }, [])

  return (
      <Viewer {...data}/>
  );
}

export default App;
